.footer {
    min-height: 90vh;
    margin-top: calc(4 * var(--gap));
    padding: calc(4 * var(--gap));
    display: grid;
    grid-area: footer;
    justify-content: center;
    background-color: hsl(var(--color-black));
    color: hsl(var(--color-white));
}

.heading {
    margin-bottom: calc(2 * var(--gap));
    font-size: var(--font-size-5);
    font-weight: 900;
    text-align: center;
}

.subheading {
    margin-top: var(--gap);
    margin-bottom: var(--gap);
    font-size: var(--font-size-2);
    font-weight: 700;
}

.wrapper {
    width: var(--global-container-width);
    max-width: calc(0.5 * var(--global-container-max-width));

    strong {
        color: hsl(var(--color-white));
    }

    @media (max-width: 1199px) {
        text-align: center;
    }
}

.address {
    display: inline-flex;
}

.image {
    vertical-align: top;

    svg {
        max-width: 16px;
        max-height: 20px;
        margin-right: calc(0.5 * var(--gap));
        fill: hsl(var(--color-gold));
    }
}

.item {
    position: relative;
    transition: color var(--transition-out) var(--transition-timing);

    &:hover,
    &:focus {
        color: hsl(var(--color-gold));
        transition-duration: var(--transition-in);

        &::before {
            transition-duration: var(--transition-in);
            border-color: hsl(var(--color-gold));
        }
    }

    &::before {
        margin-right: calc(0.5 * var(--gap));
        margin-bottom: 1px;
        padding: 3px;
        display: inline-block;
        content: '';
        transform: rotate(-45deg);
        transition: border-color var(--transition-out) var(--transition-timing);
        border-width: 0 2px 2px 0;
        border-style: solid;
        border-color: hsl(var(--color-white));
    }
}

.button {
    color: inherit;
}

.end {
    font-size: var(--font-size-00);
    text-align: center;

    p {
        margin-top: var(--gap);
    }
}
